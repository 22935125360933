/*
 * リスク評価申請 共通関数
 * コンポーネントで使用するにはmixinsで読み込む
 * import raf from '@/mixins/riskApplicationFunctions';
 * mixins: [raf],
 */

import moment from 'moment';

const FLAG = {
  ENABLED: 1,
  DISABLED: 999,
};

export default {
  /**
   * 画面表示するリスクステータスを取得します。
   * @param labels labels.jsonオブジェクト
   * @param risks 対象者のrisks配列
   * @param riskApplications 対象者のriskApplications配列
   * @param excludeRiskApplicationId 判定から除外するid
   * @return {
   *  currentRiskType: 現在進行中のリスク種別
   *  currentStatus: 現在進行中のステータス
   *  currentRiskReduced: boolean applications.is_risk_reduced 値
   *  currentStatusLabel: 現在進行中のステータスを画面表示するための文字列
   *  finalRiskType: 確定済みのリスク種別
   *  finalRiskTypeLabel: 確定済みのリスク種別を画面表示するための文字列
   *  finalRiskApplication: 確定済みのリスクとなる起点となったリスク報告書情報
   * }
   */
  getRiskStatus({
    labels = {},
    risks = [],
    riskApplications = [],
    excludeRiskApplicationId = null,
  } = {}) {
    const RISK_APPLICATION_STATUS = labels.riskApplication.status;
    const RISK_APPLICATION_RISK_TYPE = labels.riskApplication.riskType;

    const result = {
      currentRiskType: null,
      currentStatus: null,
      currentStatusLabel: null,
      finalRiskType: null,
      finalRiskTypeLabel: null,
    };

    const _getHighestRiskApplication = (rows) => {
      let highestRiskApplication;
      rows.forEach((row) => {
        if (!highestRiskApplication || highestRiskApplication.risk_type < row.risk_type) {
          highestRiskApplication = row;
        }
      });
      return highestRiskApplication;
    };

    // 進行中の申請情報
    const inProgressApplications = riskApplications.filter((row) => RISK_APPLICATION_STATUS.APPLIED <= row.status && row.status < RISK_APPLICATION_STATUS.APPROVED && row.id !== excludeRiskApplicationId);
    if (inProgressApplications.length > 0) {
      // その中でも最上位のリスク種別を返す
      const target = _getHighestRiskApplication(inProgressApplications);
      result.currentRiskType = target.risk_type;
      result.currentStatus = target.status;
      result.currentRiskReduced = target.is_risk_reduced;
      result.currentStatusLabel = `${RISK_APPLICATION_RISK_TYPE[target.risk_type].label}申請中`;
    } else {
      // 下書きを見てみる
      const inProgressDrafts = riskApplications.filter((row) => row.status === RISK_APPLICATION_STATUS.APPLYER_DRAFTED && row.id !== excludeRiskApplicationId);
      if (inProgressDrafts.length > 0) {
        // その中でも最上位のリスク種別を返す
        const target = _getHighestRiskApplication(inProgressDrafts);
        result.currentRiskType = target.risk_type;
        result.currentStatus = target.status;
        result.currentRiskReduced = target.is_risk_reduced;
        result.currentStatusLabel = `${RISK_APPLICATION_RISK_TYPE[target.risk_type].label} 下書き中`;
      }
    }

    // 確定済みのステータス情報
    const finalRisk = risks.sort((a, b) => b.id - a.id).find((row) => row.flag === FLAG.ENABLED); // 降順に並べたうえで先頭の生きているレコードを参照する
    const notDisplayRiskType = 11; // 「問題なし（回復）」リスク種別は（原則として）画面表示しない
    const finalRiskApplication = finalRisk && finalRisk.risk_application_id // 最終リスクの起因となった申請情報
      ? riskApplications.find((row) => row.id === finalRisk.risk_application_id)
      : null;

    result.finalRiskApplication = finalRiskApplication;

    if (finalRisk && finalRisk.type !== notDisplayRiskType && RISK_APPLICATION_RISK_TYPE[finalRisk.type]) {
      // 確定済みのリスク評価がある
      result.finalRiskType = finalRisk.type;
      result.finalRiskTypeLabel = RISK_APPLICATION_RISK_TYPE[finalRisk.type].selectable
        ? `${RISK_APPLICATION_RISK_TYPE[finalRisk.type].label}報告済(${moment(finalRisk.created_at).format('YYYY/MM/DD')})`
        : RISK_APPLICATION_RISK_TYPE[finalRisk.type].label; // '差し戻し' が該当

    } else if (finalRisk && finalRisk.type === notDisplayRiskType && finalRiskApplication) {
      // 「問題なし（回復）」リスクであっても、申請を経てその状態になっている場合は申請履歴を表示する
      result.finalRiskType = finalRisk.type; // 「問題なし（回復）」
      result.finalRiskTypeLabel = `リスク取り下げ報告済(${moment(finalRisk.created_at).format('YYYY/MM/DD')})`;

    } else if (!result.currentStatusLabel) {
      // 進行中のステータスもなければ何もない状態と確定
      result.finalRiskTypeLabel = '申請なし';
    }

    return result;
  },

  /**
   * 画面表示するリスク報告理由を取得します。
   * @param labels labels.jsonオブジェクト
   * @param riskType リスク種別
   * @param riskTypeTags 選択中のリスク評価基準
   * @param riskTypeTagNote 「その他」で入力された文字列
   * @param onlyTagsLabel riskTypeTags および riskTypeTagNote で表現される文字列のみを返却するか
   * @return リスク報告理由
  */
  getRiskReportReasonTitle({
    labels = {},
    riskType = null,
    riskTypeTags = [],
    riskTypeTagNote = null,
    onlyTagsLabel = false,
  } = {}) {
    if (!riskType) {
      return '';
    }

    const RISK_APPLICATION_RISK_TYPE = labels.riskApplication.riskType;
    const riskTypeName = RISK_APPLICATION_RISK_TYPE[riskType].label;

    if (riskType === 11) {
      // 「問題なし（回復）」の場合は具体的な報告基準はない
      return onlyTagsLabel ? '-' : riskTypeName;
    }

    const RISK_TYPE_TAGS = RISK_APPLICATION_RISK_TYPE[riskType].tags;
    const targetTags = riskTypeTags.map((tagVal) => RISK_TYPE_TAGS.find((TAG) => TAG.value === tagVal));
    const tagNames = targetTags.map((tag) => {
      if (tag.needInput) {
        return `${tag.label}（${riskTypeTagNote}）`;
      }
      return tag.label;
    });
    return onlyTagsLabel
      ? tagNames.join('、')
      : `${riskTypeName}（${tagNames.join('、')}）`;
  },

  /**
   * 対象のリスク報告書はログインユーザが扱える下書きデータか判定する
   * @param {Object} labels helper.master.labels
   * @param {Object} user state.user
   * @param {Object} riskApplication リスク報告書データ
   * @returns 扱える場合はtrue, 扱えない場合はfalse
   */
  isMyDraft({
    labels = {},
    user = {},
    riskApplication = null,
  }) {
    // データの存在チェック
    if (!riskApplication) {
      console.log('Not found the risk application.');
      return false;
    }
    // データの下書き者チェック（ログインユーザと同一である必要がある）
    if (riskApplication.applyer_user_id !== user.id) {
      console.log('Not your draft.');
      return false;
    }
    // データの状態チェック（下書き状態である必要がある）
    if (riskApplication.status !== labels.riskApplication.status.APPLYER_DRAFTED) {
      console.log('Not draft data.');
      return false;
    }
    return true;
  },

  /**
   * 子windowの名前を取得する
   * @param {String} counselingType 健康相談種別
   * @param {String} foreignId キー情報
   * @returns メソッド名
   */
  getChildWindowName({
    counselingType = null,
    foreignId = null,
  }) {
    return `wtt_risk_application_${counselingType}_${foreignId}`;
  },

  /**
   * リスク報告書のアクションを伝播するためのメソッド名を取得する
   * @param {String} childWindowName コンポーネントから起動するリスク報告書のwindow名
   * @returns メソッド名
   */
  getEmitFunctionName(childWindowName) {
    return `__wtt__function__${childWindowName}`;
  },

  /**
   * 【重要】
   * コンポーネントで直接使用するには
   * methodsオブジェクトに入れておく必要がある
   */
  methods: {
    
    /**
     * リスク報告書のラベルを返却する
     * @param {Object} riskApplication リスク報告書情報
     * @returns リスク報告書のラベル
     */
    getRiskApplicationLabel(riskApplication = {}) {
      return riskApplication && riskApplication.is_risk_reduced ? '取り下げ報告書' : 'リスク報告書';
    },
  },
};
