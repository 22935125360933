<template>
  <div>
    <spacer
      v-if="!flag.loadFinished"
      :y="2.5"
    />
    <loader-simple :flag="!flag.loadFinished">
      <div
        v-if="flag.loadFinished"
        :class="$style.riskApplicationIndicator"
      >

        <div v-if="reportedApplication">
          <basic-btn
            tag="button"
            size="sm"
            addClass="alert"
            v-on:click="clickRefer"
          >リスク判断済</basic-btn>
        </div>
        <div
          v-else
          :class="$style.riskApplicationbBtns"
        >
          <basic-btn
            tag="button"
            addClass="risk"
            v-on:click="clickApply"
            :disabled="flag.disabledApply">リスク評価申請</basic-btn>
          <basic-btn
            v-if="flag.showReduceRisk"
            tag="button"
            addClass="risk"
            v-on:click="clickReduceRisk"
            :disabled="flag.disabledReduceRisk">リスク取り下げ</basic-btn>
        </div>

        <div>
          <p
            v-if="riskStatus.currentStatusLabel"
            :class="$style.status"
          >
            <span
              v-if="riskStatus.currentRiskReduced"
              :class="[$style.boxTag, $style.reduceRisk]"
            >リスク取り下げ</span>
            <span>{{ riskStatus.currentStatusLabel }}</span>
          </p>
          <p
            v-if="riskStatus.finalRiskTypeLabel"
            :class="$style.status"
          >
            <span
              v-if="riskStatus.finalRiskApplication && riskStatus.finalRiskApplication.is_risk_reduced"
              :class="[$style.boxTag, $style.reduceRisk]"
            >リスク取り下げ</span>
            <span>{{ riskStatus.finalRiskTypeLabel }}</span>
          </p>
        </div>
      </div>
    </loader-simple>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';

import raf from '@/mixins/riskApplicationFunctions';

export default {
  name: 'risk-application-btn',
  mixins: [
    raf,
  ],
  components: {
    LoaderSimple,
    Spacer,
    BasicBtn,
  },
  props: {
    counseling_type: {
      type: String, // counseling_type.counseling_type
      // 1 => オンライン健康相談
      // 2 => テキスト健康相談
      // 3 => バーチャル健康相談
    },
    foreign_id: {
      type: String, // counseling_type.foreign_id
    },
    target_user_id: {
      type: String,
    },
    callback: {
      type: Function,
    },
  },
  data() {
    return {
      flag: {
        loadFinished: false,
        disabledApply: false,
        showReduceRisk: false, // デフォルト非表示
        disabledReduceRisk: false,
      },
      riskStatus: { // riskApplicationFunctions.js#getRiskStatus の返却値
        currentRiskType: null,
        currentStatus: null,
        currentStatusLabel: null,
        finalRiskType: null,
        finalRiskTypeLabel: null,
      },
      myDraftId: null, // 下書きがある場合にセット（riskApplicationId）
      reportedApplication: null, // 当健康相談を起点として申請され、その後承認済みとなったリスク報告書
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    canApply() {
      return !this.flag.isHighestLevel && !this.flag.hasOtherDraft;
    },
    childWindowName() {
      return raf.getChildWindowName({
        counselingType: this.counseling_type,
        foreignId: this.foreign_id,
      });
    },
  },
  async created() {
    this.initialize();
  },
  mounted() {
    // コールバック関数をセット
    const functionName = raf.getEmitFunctionName(this.childWindowName);
    if (!window[functionName]) {
      window[functionName] = this.postUpdateRequest;
    }
  },
  beforeUnmount() {
    // コールバック関数を解除
    const functionName = raf.getEmitFunctionName(this.childWindowName);
    if (window[functionName]) {
      delete window[functionName];
    }
  },
  methods: {
    async initialize() {
      // 変数初期化
      this.myDraftId = null;

      // 対象者に関連するリスク評価サマリを取得する
      let response;
      try {
        response = await this.axios({
          method: 'GET',
          url: '/v1/riskApplications/get/summary',
          params: {
            target_user_id: this.target_user_id,
          },
        });
      } catch (e) {
        console.log('Failed to get risk application summary.');
        return;
      }
      const targetUser = response.data.data.targetUser;
      if (targetUser.flag === 999) {
        // 対象者が無効化済みの場合はボタンを出さない
        console.log(`The user(${targetUser.id}) is deleted.`);
        return;
      }
      const riskApplications = response.data.data.riskApplications;

      // 最新状態を取得、表示する
      this.riskStatus = raf.getRiskStatus({
        labels: this.helper.master.labels,
        risks: targetUser.risk,
        riskApplications,
      });

      // 当健康相談を起点として申請され、その後承認済みとなったリスク報告書があるか
      this.reportedApplication = riskApplications.find((row) => row.counseling_type === Number(this.counseling_type) && row.foreign_id === this.foreign_id && row.status === this.helper.master.labels.riskApplication.status.APPROVED);
      if (!this.reportedApplication) {
        // 各種ボタン制御

        // 「リスク評価申請」ボタンの表示制御
        this.setApplyBtnStatus({
          riskApplications,
          riskStatus: this.riskStatus,
        });

        // 「リスク取り下げ」ボタンを表示するか
        this.setReduceRiskBtnStatus({
          risks: targetUser.risk,
          riskApplications,
        });

        // 自分の下書きデータがあるか
        const STATUS_APPLYER_DRAFTED = this.helper.master.labels.riskApplication.status.APPLYER_DRAFTED;
        const myDraft = riskApplications.find((row) => row.applyer_user_id === this.user.id
          && row.status === STATUS_APPLYER_DRAFTED
          && row.counseling_type === Number(this.counseling_type)
          && row.foreign_id === this.foreign_id
          && row.target_user_id === this.target_user_id);
        if (myDraft) {
          this.myDraftId = myDraft.id;
        }
      }

      this.flag.loadFinished = true;
    },
    setApplyBtnStatus({
      riskApplications = [],
      riskStatus,
    }) {
      const RISK_TYPE = this.helper.master.labels.riskApplication.riskType;
      const RISK_APPLICATION_STATUS = this.helper.master.labels.riskApplication.status;

      // 申請を行わせない条件
      // 1. 他人が下書き中
      // 2. 他人がハイリスク申請中
      // 3. ハイリスク報告済み
      // 4. 自分の申請が進行中
      // 5. 自分が「リスク取り下げ申請」の下書き中

      // 1. 他人が下書き中
      const hasOtherDraft = riskApplications.some((riskApplication) => riskApplication.applyer_user_id !== this.user.id && riskApplication.status === RISK_APPLICATION_STATUS.APPLYER_DRAFTED);
      if (hasOtherDraft) {
        this.flag.disabledApply = true;
      }
      // 2. 他人がハイリスク申請中
      const hasOtherApplied = riskApplications.some((riskApplication) => riskApplication.applyer_user_id !== this.user.id && RISK_APPLICATION_STATUS.APPLIED <= riskApplication.status && riskApplication.status < RISK_APPLICATION_STATUS.APPROVED);
      if (hasOtherApplied && RISK_TYPE[riskStatus.currentRiskType] && RISK_TYPE[riskStatus.currentRiskType].isHighestLevel) {
        this.flag.disabledApply = true;
      }
      // 3. ハイリスク報告済み
      if (RISK_TYPE[riskStatus.finalRiskType] && RISK_TYPE[riskStatus.finalRiskType].isHighestLevel) {
        this.flag.disabledApply = true;
      }
      // 4. 自分の申請が進行中
      const myApplications = riskApplications.filter((riskApplication) => riskApplication.applyer_user_id === this.user.id);
      if (myApplications.some((row) => RISK_APPLICATION_STATUS.APPLIED <= row.status && row.status < RISK_APPLICATION_STATUS.APPROVED)) {
        this.flag.disabledApply = true;
      }
      // 5. 自分が「リスク取り下げ申請」の下書き中
      if (myApplications.some((row) => row.status === RISK_APPLICATION_STATUS.APPLYER_DRAFTED && row.is_risk_reduced)) {
        this.flag.disabledApply = true;
      }
    },
    setReduceRiskBtnStatus({
      risks = [],
      riskApplications = [],
    }) {
      const RISK_APPLICATION_STATUS = this.helper.master.labels.riskApplication.status;

      // ミドルリスクまたはハイリスク認定されている場合はボタンを表示するか
      if (risks.length === 0) {
        return;
      }
      const targetRisk = risks.find((risk) => risk.flag !== 999); // 削除されていない最新のリスク
      const reducibleRiskTypes = [
        1, // ミドルリスク
        2, // ハイリスク
      ];
      this.flag.showReduceRisk = targetRisk && reducibleRiskTypes.includes(targetRisk.type);

      if (!this.flag.showReduceRisk) {
        return;
      }

      // リスク取り下げ申請を行わせない条件
      // 1. 他人が下書き中
      // 2. 他人が申請中
      // 3. 自分の申請が進行中
      // 4. 自分が「通常申請」の下書き中

      // 1. 他人が下書き中
      const hasOtherDraft = riskApplications.some((riskApplication) => riskApplication.applyer_user_id !== this.user.id && riskApplication.status === RISK_APPLICATION_STATUS.APPLYER_DRAFTED);
      if (hasOtherDraft) {
        this.flag.disabledReduceRisk = true;
      }
      // 2. 他人が申請中
      const hasOtherApplied = riskApplications.some((riskApplication) => riskApplication.applyer_user_id !== this.user.id && RISK_APPLICATION_STATUS.APPLIED <= riskApplication.status && riskApplication.status < RISK_APPLICATION_STATUS.APPROVED);
      if (hasOtherApplied) {
        this.flag.disabledReduceRisk = true;
      }
      // 3. 自分の申請が進行中
      const myApplications = riskApplications.filter((riskApplication) => riskApplication.applyer_user_id === this.user.id);
      if (myApplications.some((row) => RISK_APPLICATION_STATUS.APPLIED <= row.status && row.status < RISK_APPLICATION_STATUS.APPROVED)) {
        this.flag.disabledReduceRisk = true;
      }
      // 4. 自分が「通常申請」の下書き中
      if (myApplications.some((row) => row.status === RISK_APPLICATION_STATUS.APPLYER_DRAFTED && !row.is_risk_reduced)) {
        this.flag.disabledReduceRisk = true;
      }
    },
    clickApply() {
      // 別タブでフォームを開く
      if (this.myDraftId) {
        // 下書きデータがある場合は下書きをもとに申請フォームを開く
        window.open(`/counselor/risk-applications/apply/?draft_id=${this.myDraftId}`, this.childWindowName);
      } else {
        // 新規申請
        window.open(`/counselor/risk-applications/apply/?counseling_type=${this.counseling_type}&foreign_id=${this.foreign_id}`, this.childWindowName);
      }
    },
    clickReduceRisk() {
      // 別タブでフォームを開く
      if (this.myDraftId) {
        // 下書きデータがある場合は下書きをもとに申請フォームを開く
        window.open(`/counselor/risk-applications/apply/?draft_id=${this.myDraftId}`, this.childWindowName);
      } else {
        // 新規申請
        window.open(`/counselor/risk-applications/apply/?counseling_type=${this.counseling_type}&foreign_id=${this.foreign_id}&reduce_risk=1`, this.childWindowName);
      }
    },
    clickRefer() {
      // 別タブで開く
      window.open(`/counselor/risk-applications/${this.reportedApplication.id}`, this.childWindowName);
    },
    /**
     * このメソッドは子windowでロードされたRiskApplicationFormコンポーネントから実行される。
     * RiskApplicationForm上でリスク報告申請書へのアクションが行われた時、このメソッドを呼び出すことで情報の最新化を行う。
     */
    postUpdateRequest() {
      this.initialize(); // 再初期化する
    },
  },
};
</script>

<style lang="scss" module>
.riskApplicationIndicator {
  margin-top: 2em;
  display: flex;
  align-items: center;
  .status {
    margin-left: 1em;
  }
}
.riskApplicationbBtns {
  > button {
    display: block !important;
    &:not(:first-child) {
      margin-top: .5rem
    }
  }
}
.boxTag {
  font-weight: bold;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 4px;
  margin-right: .5rem;

  @include sm-view {
    padding: 3px 7px 2px;
    font-size: 11px;
  }

  &.reduceRisk {
    background-color: var(--gray-main);
    color: #fff;
  }
}
</style>
