<template>
  <div>
    <spacer :y="5"/>
    <ul>

      <stack-item>
        <contents-box title="検索">
          <div :class="$style.search">
            <div :class="$style.searchArea">
              <dl>
                <dt>キーワード</dt>
                <dd>
                  <input
                    type="text"
                    :class="$style.input"
                    v-model.trim="searchCondition.keyword"
                    placeholder="キーワードで検索"
                  >
                </dd>
              </dl>
              <spacer :y="2" />
              <dl>
                <dt>対象者種別</dt>
                <ul :class="$style.targetUserAccountTypes">
                  <li
                    v-for="userType in targetUserAccountTypes"
                    :key="userType.value"
                  >
                    <input
                      type="checkbox"
                      :name="`targetUserAccountType-${userType.value}`"
                      :id="`targetUserAccountType-${userType.value}`"
                      :value="userType.value"
                      v-model="searchCondition.targetUserAccountTypes"
                    >
                    <label :for="`targetUserAccountType-${userType.value}`">{{ userType.label }}</label>
                  </li>
                </ul>
              </dl>
              <spacer :y="2"/>
              <dl>
                <dt><i class="fa-solid fa-calendar-days"></i>申請期間</dt>
                <dd>
                  <div>
                    <date-small-picker
                      :name="'searchCondition_appliedAtFrom'"
                      v-model="searchCondition.appliedAtFrom"
                      v-on:sendDate="setSearchConditionAppliedAt" />
                  </div>
                    <date-small-picker
                      :name="'searchCondition_appliedAtTo'"
                      v-model="searchCondition.appliedAtTo"
                      v-on:sendDate="setSearchConditionAppliedAt" />
                </dd>
              </dl>
              <div v-if="!searchCondition.appliedAtFrom && searchCondition.appliedAtTo">
                <spacer :y="1"/>
                <p :class="$style.alert_text">申請期間の開始日を選択してください</p>
              </div>
              <spacer :y="1"/>
              <dl>
                <div v-if="searchCondition.appliedAtFrom && searchCondition.appliedAtTo && !(fitlyAppliedPeriod)" :class="$style.alert_text">正しい期間を選択してください</div>
              </dl>
              <spacer :y="1"/>
              <dl>
                <dt><i class="fa-solid fa-calendar-days"></i>報告期間</dt>
                <dd>
                  <div>
                    <date-small-picker
                      :name="'searchCondition_approvalActionAtFrom'"
                      v-model="searchCondition.approvalActionAtFrom"
                      v-on:sendDate="setSearchConditionApprovalActionAt" />
                  </div>
                    <date-small-picker
                      :name="'searchCondition_approvalActionAtTo'"
                      v-model="searchCondition.approvalActionAtTo"
                      v-on:sendDate="setSearchConditionApprovalActionAt" />
                </dd>
              </dl>
              <div v-if="!searchCondition.approvalActionAtFrom && searchCondition.approvalActionAtTo">
                <spacer :y="1"/>
                <p :class="$style.alert_text">申請期間の開始日を選択してください</p>
              </div>
              <spacer :y="1"/>
              <dl>
                <div v-if="searchCondition.approvalActionAtFrom && searchCondition.approvalActionAtTo && !(fitlyApprovalActionPeriod)" :class="$style.alert_text">正しい期間を選択してください</div>
              </dl>
              <spacer :y="1"/>
              <dl>
                <dt>種別</dt>
                <ul :class="$style.radio">
                  <li>
                    <div>
                      <input
                        type="radio"
                        name="riskType-all"
                        id="riskType-all"
                        value=""
                        v-model="searchCondition.riskType"
                        v-on:change="changeRiskType"
                      >
                      <label for="riskType-all">すべて</label>
                    </div>
                  </li>
                  <li
                    v-for="(type, key) in selectableRiskTypes"
                    :key="key"
                  >
                    <div>
                      <input
                        type="radio"
                        :name="`riskType-${key}`"
                        :id="`riskType-${key}`"
                        :value="key"
                        v-model="searchCondition.riskType"
                        v-on:change="changeRiskType"
                      >
                      <label :for="`riskType-${key}`">{{ type.label }}</label>
                    </div>
                  </li>
                </ul>
              </dl>
              <spacer :y="2" />
              <dl>
                <dt>報告基準</dt>
                <ul :class="$style.riskTypeTags">
                  <template v-if="selectableRiskTypeTags.length">
                    <li
                      v-for="tag in selectableRiskTypeTags"
                      :key="tag.value"
                    >
                      <input
                        type="checkbox"
                        :name="`riskTypeTag-${tag.value}`"
                        :id="`riskTypeTag-${tag.value}`"
                        :value="tag.value"
                        v-model="searchCondition.riskTypeTags"
                      >
                      <label :for="`riskTypeTag-${tag.value}`">{{ tag.label }}</label>
                    </li>
                  </template>
                  <span v-else>-</span>
                </ul>
              </dl>
              <spacer :y="2" />
              <dl>
                <dt>除外</dt>
                <div :class="$style.checkBox">
                  <input
                    type="checkbox"
                    id="excludeTest"
                    v-model="searchCondition.flag.excludeTest"
                  >
                  <label for="excludeTest">テストアカウント</label>
                </div>
              </dl>
              <spacer :y="2" />
              <dl :class="$style.select">
                <dt>学校・企業ソート</dt>
                <dd>
                  <select
                    :class="$style.select_box"
                    name="school"
                    id="school"
                    v-on:change="changeSchool"
                  >
                    <option :value="0" :selected="searchCondition.targetSchoolId === 0">すべて</option>
                    <option
                      v-for="n of schoolsAll"
                      :key="n"
                      :value="n.id"
                      :selected="n.id === searchCondition.targetSchoolId">{{ n.name }}</option>
                  </select>
                </dd>
              </dl>
              <spacer :y="2" />
              <dl :class="$style.select">
                <dt>医師・心理士ソート</dt>
                <dd>
                  <select
                    :class="$style.select_box"
                    name="medicalWorker"
                    id="medicalWorker"
                    v-model="searchCondition.targetMedicalWorkerId"
                  >
                    <option :value="0">すべて</option>
                    <option
                      v-for="medicalWorker of medicalWorkersAll"
                      :key="medicalWorker"
                      :value="medicalWorker.id"
                    >{{ medicalWorker.username }}</option>
                  </select>
                </dd>
              </dl>
            </div>
            <spacer :y="4" />
            <div :class="$style.button_area">
              <basic-btn
                tag="button"
                :disabled="flag.loader || !validSearchCondition"
                @click="clickSearch">
                <sapn>検索</sapn>
              </basic-btn>
              <spacer :x="2"/>
              <basic-btn
                tag="button"
                :disabled="flag.loader"
                @click="clickClear">
                <sapn>クリア</sapn>
              </basic-btn>
            </div>
          </div>
        </contents-box>
      </stack-item>

      <stack-item id="risk-management-content-applied">
        <contents-box title="リスク評価申請">
          <p
            v-if="pagination.applied.total > 0"
            :class="$style.bold"
          >{{ pagination.applied.total }}件</p>
          <pagination
            v-if="pagination.applied.lastPage > 1"
            :page="pagination.applied.page"
            :pageMax="pagination.applied.lastPage"
            :needEmit="true"
            v-on:click-page="movePage($event, 'applied')"
          />
          <loader-simple :flag="flag.loader">
            <div>
              <spacer :y="2"/>
              <ul v-if="riskApplications.applied.length > 0">
                <stack-item
                  v-for="row in riskApplications.applied"
                  :key="row.id"
                >
                  <risk-application-box
                    :riskApplication="row"
                    :targetUserLink="getTargetUserLink(row.target_user_id)"
                    :forceShowNew="isForceShowNew(row)"
                  >
                    <template v-slot:btn>
                      <btn-container>
                        <basic-btn
                          tag="button"
                          size="sm"
                          v-on:click="clickReview(row)"
                        >内部評価</basic-btn>
                      </btn-container>
                    </template>
                  </risk-application-box>
                </stack-item>
              </ul>
              <p v-if="!flag.loader && riskApplications.applied.length === 0">現在、申請中のリスク報告はありません</p>
              <spacer :y="2"/>
              <pagination
                v-if="!flag.loader && pagination.applied.lastPage > 1"
                :page="pagination.applied.page"
                :pageMax="pagination.applied.lastPage"
                :needEmit="true"
                v-on:click-page="movePage($event, 'applied')"
              />
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>

      <stack-item id="risk-management-content-review">
        <contents-box title="内部評価">
          <p
            v-if="pagination.review.total > 0"
            :class="$style.bold"
          >{{ pagination.review.total }}件</p>
          <pagination
            v-if="pagination.review.lastPage > 1"
            :page="pagination.review.page"
            :pageMax="pagination.review.lastPage"
            :needEmit="true"
            v-on:click-page="movePage($event, 'review')"
          />
          <loader-simple :flag="flag.loader">
            <div>
              <spacer :y="2"/>
              <ul v-if="riskApplications.review.length > 0">
                <stack-item
                  v-for="row in riskApplications.review"
                  :key="row.id"
                >
                  <risk-application-box
                    :riskApplication="row"
                    :targetUserLink="getTargetUserLink(row.target_user_id)"
                    :hideDraftedTag="true"
                    :manageReadFlag="isRiskApplicationNotRefered(row.id)"
                  >
                    <template v-slot:btn>
                      <btn-container>
                        <basic-btn
                          tag="button"
                          size="sm"
                          v-on:click="clickRefer(row)"
                        >{{ getRiskApplicationLabel(row) }}</basic-btn>
                      </btn-container>
                    </template>
                  </risk-application-box>
                </stack-item>
              </ul>
              <p v-if="!flag.loader && riskApplications.review.length === 0">内部評価済のリスク報告はありません</p>
              <spacer :y="2"/>
              <pagination
                v-if="!flag.loader && pagination.review.lastPage > 1"
                :page="pagination.review.page"
                :pageMax="pagination.review.lastPage"
                :needEmit="true"
                v-on:click-page="movePage($event, 'review')"
              />
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>

      <stack-item id="risk-management-content-approved">
        <contents-box title="リスク報告済">
          <p
            v-if="pagination.approved.total > 0"
            :class="$style.bold"
          >{{ pagination.approved.total }}件</p>
          <pagination
            v-if="pagination.approved.lastPage > 1"
            :page="pagination.approved.page"
            :pageMax="pagination.approved.lastPage"
            :needEmit="true"
            v-on:click-page="movePage($event, 'approved')"
          />
          <loader-simple :flag="flag.loader">
            <div>
              <spacer :y="2"/>
              <ul v-if="riskApplications.approved.length > 0">
                <stack-item
                  v-for="row in riskApplications.approved"
                  :key="row.id"
                >
                  <risk-application-box
                    :riskApplication="row"
                    :targetUserLink="getTargetUserLink(row.target_user_id)"
                    :manageReadFlag="isRiskApplicationNotRefered(row.id)"
                    @risk-application-message-posted="getRiskApplications('approved')"
                  >
                    <template v-slot:btn>
                      <btn-container>
                        <basic-btn
                          tag="button"
                          size="sm"
                          v-on:click="clickRefer(row)"
                        >{{ getRiskApplicationLabel(row) }}</basic-btn>
                      </btn-container>
                    </template>
                  </risk-application-box>
                </stack-item>
              </ul>
              <p v-if="!flag.loader && riskApplications.approved.length === 0">該当するリスク報告はありません</p>
              <spacer :y="2"/>
              <pagination
                v-if="!flag.loader && pagination.approved.lastPage > 1"
                :page="pagination.approved.page"
                :pageMax="pagination.approved.lastPage"
                :needEmit="true"
                v-on:click-page="movePage($event, 'approved')"
              />
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>

      <stack-item id="risk-management-content-denied">
        <contents-box title="差し戻し">
          <p
            v-if="pagination.denied.total > 0"
            :class="$style.bold"
          >{{ pagination.denied.total }}件</p>
          <pagination
            v-if="pagination.denied.lastPage > 1"
            :page="pagination.denied.page"
            :pageMax="pagination.denied.lastPage"
            :needEmit="true"
            v-on:click-page="movePage($event, 'denied')"
          />
          <loader-simple :flag="flag.loader">
            <div>
              <spacer :y="2"/>
              <ul v-if="riskApplications.denied.length > 0">
                <stack-item
                  v-for="row in riskApplications.denied"
                  :key="row.id"
                >
                  <risk-application-box
                    :riskApplication="row"
                    :targetUserLink="getTargetUserLink(row.target_user_id)"
                    :manageReadFlag="isRiskApplicationNotRefered(row.id)"
                  >
                    <template v-slot:btn>
                      <btn-container>
                        <basic-btn
                          tag="button"
                          size="sm"
                          v-on:click="clickRefer(row)"
                        >{{ getRiskApplicationLabel(row) }}</basic-btn>
                      </btn-container>
                    </template>
                  </risk-application-box>
                </stack-item>
              </ul>
              <p v-if="!flag.loader && riskApplications.denied.length === 0">該当するリスク報告はありません</p>
              <spacer :y="2"/>
              <pagination
                v-if="!flag.loader && pagination.denied.lastPage > 1"
                :page="pagination.denied.page"
                :pageMax="pagination.denied.lastPage"
                :needEmit="true"
                v-on:click-page="movePage($event, 'denied')"
              />
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>

    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import Spacer from '@/views/components/Spacer.vue';
import RiskApplicationBox from '@/views/components/RiskApplicationBox.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';
import Pagination from '@/views/components/Pagination.vue';

import cf from '@/mixins/commonFunctions.js';
import raf from '@/mixins/riskApplicationFunctions';

const SEARCH_CONDITION_DEFAULT = {
  keyword: null,
  targetUserAccountTypes: [],
  appliedAtFrom: null,
  appliedAtTo: null,
  approvalActionAtFrom: null,
  approvalActionAtTo: null,
  riskType: '',
  riskTypeTags: [],
  targetSchoolId: 0,
  targetMedicalWorkerId: 0,
  flag: {
    excludeTest: false,
  },
};

const PAGINATION_DEFAULT = {
  page: 1, // 現在ページ
  perPage: 10, // ページ毎件数
  lastPage: 1, // 最後ページ(データ取得の都度更新)
  total: 0, // 総件数(データ取得の都度更新)
};

export default {
  name: 'operator-risk-management',
  mixins: [
    cf,
    raf,
  ],
  components: {
    StackItem,
    ContentsBox,
    LoaderSimple,
    BtnContainer,
    BasicBtn,
    Spacer,
    RiskApplicationBox,
    DateSmallPicker,
    Pagination,
  },
  data() {
    return {
      flag: {
        loader: true,
        search: {
          isSearching: false, // 現在が検索条件による絞り込み状態か
        },
      },
      targetUserAccountTypes: [
        {
          value: 1,
          label: '在学生・プログラム参加者',
        },
        {
          value: 2,
          label: '保護者',
        },
        {
          value: 3,
          label: '教職員・企業担当者',
        },
      ],
      riskApplications: {
        applied: [], // 申請済み（=内部評価待ち | 内部評価者下書き中）
        review: [], // 内部評価（内部評価済み）
        approved: [], // リスク報告済み
        denied: [], // 差し戻し済み
      },
      pagination: {
        applied: cloneDeep(PAGINATION_DEFAULT),
        review: cloneDeep(PAGINATION_DEFAULT),
        approved: cloneDeep(PAGINATION_DEFAULT),
        denied: cloneDeep(PAGINATION_DEFAULT),
      },
      searchCondition: cloneDeep(SEARCH_CONDITION_DEFAULT),
      medicalWorkersAll: [],
      schoolsAll: [],
      childWindowNames: [],
      referedIds: [], // 当コンポーネントから詳細を呼び出したリスク報告書id
    };
  },
  beforeCreate() {
    // リスク評価タブ -> [popup]リスク報告書 -> 上部のTOPリンク などの経路で、子画面として表示したwindowがメイン画面として生き続ける場合、window.openのターゲットに再びなってしまうケースがある
    // これを防ぐため、リスク報告書をpopupする基点となる画面の初期化処理でwindow.nameに固定の文字列をセットする
    window.name = 'wtt-operator-risk-management';
  },
  created() {
    if (this.user && this.user.email) {
      this.getRiskApplications();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getRiskApplications();
        }
      });
    }
    // 検索条件エリアの選択肢取得
    this.getSchools();
    this.getMedicalWorkers();
  },
  beforeUnmount() {
    // コールバック関数を解除
    this.childWindowNames.forEach((childWindowName) => {
      const functionName = raf.getEmitFunctionName(childWindowName);
      if (window[functionName]) {
        delete window[functionName];
      }
    });
  },
  computed: {
    ...mapState(['helper', 'user']),
    fitlyAppliedPeriod() {
      return moment(this.searchCondition.appliedAtTo).isAfter(moment(this.searchCondition.appliedAtFrom));
    },
    fitlyApprovalActionPeriod() {
      return moment(this.searchCondition.approvalActionAtTo).isAfter(moment(this.searchCondition.approvalActionAtFrom));
    },
    validSearchCondition() {
      // 申請期間
      // 片一方だけの入力は不可
      if ((this.searchCondition.appliedAtFrom && !this.searchCondition.appliedAtTo) || (!this.searchCondition.appliedAtFrom && this.searchCondition.appliedAtTo)) {
        return false;
      }
      // 不正期間は不可
      if (this.searchCondition.appliedAtFrom && this.searchCondition.appliedAtTo && !(this.fitlyAppliedPeriod)) {
        return false;
      }
      // 報告期間
      // 片一方だけの入力は不可
      if ((this.searchCondition.approvalActionAtFrom && !this.searchCondition.approvalActionAtTo) || (!this.searchCondition.approvalActionAtFrom && this.searchCondition.approvalActionAtTo)) {
        return false;
      }
      // 不正期間は不可
      if (this.searchCondition.approvalActionAtFrom && this.searchCondition.approvalActionAtTo && !(this.fitlyApprovalActionPeriod)) {
        return false;
      }
      return true;
    },
    selectableRiskTypes() {
      return this.helper.master.labels.riskApplication.riskType;
    },
    selectableRiskTypeTags() {
      if (!this.searchCondition.riskType) {
        return [];
      }
      return this.selectableRiskTypes[this.searchCondition.riskType].tags;
    },
    isRiskApplicationNotRefered() {
      // 当コンポーネントから詳細表示されていない場合は true（実際のreadFlags値によってNEWを表示するか決定する）
      // 当コンポーネントから詳細表示されている場合は false（NEWを非表示化する）
      return (id) => !this.referedIds.includes(id);
    },
  },
  methods: {
    getTargetUserLink(userId) {
      return `/operator/history/${userId}`;
    },
    isForceShowNew(riskApplication) {
      // 申請状態の場合のみ表示（下書きにはNEWを表示しない）
      return riskApplication.status === this.helper.master.labels.riskApplication.status.APPLIED;
    },
    async getRiskApplications(target = null) { // target: 取得対象プロパティ
      this.flag.loader = true;
      // 表示情報初期化
      if (target !== null) {
        this.riskApplications[target] = [];
      } else {
        this.riskApplications = {
          applied: [],
          review: [],
          approved: [],
          denied: [],
        };
      }
      // 内部関数を定義
      const _getData = async (statuses, pagination) => {
        const response = await this.axios({
          method: 'GET',
          url: '/v1/riskApplications/get/list',
          params: this.getSearchParams(statuses, pagination),
        });
        const result = response.data.data; // ページ情報なども含むオブジェクト
        // pagination情報の更新
        pagination.lastPage = result.lastPage;
        pagination.total = result.total;
        // 実データの返却
        return result.data;
      };
      // 画面表示するステータスごとに取得
      try {
        await Promise.all([
          // 申請済み（=内部評価待ち）情報の取得
          (async () => {
            if (target && target !== 'applied') {
              return;
            }
            this.riskApplications.applied = await _getData([
              this.helper.master.labels.riskApplication.status.APPLIED,
              this.helper.master.labels.riskApplication.status.REVIEWER_DRAFTED,
            ], this.pagination.applied);
          })(),
          // 内部評価情報の取得
          (async () => {
            if (target && target !== 'review') {
              return;
            }
            this.riskApplications.review = await _getData([
              this.helper.master.labels.riskApplication.status.REVIEWED,
              this.helper.master.labels.riskApplication.status.APPROVER_DRAFTED,
            ], this.pagination.review);
          })(),
          // リスク報告済み情報の取得
          (async () => {
            if (target && target !== 'approved') {
              return;
            }
            this.riskApplications.approved = await _getData([
              this.helper.master.labels.riskApplication.status.APPROVED,
            ], this.pagination.approved);
          })(),
          // 差し戻し済み情報の取得
          (async () => {
            if (target && target !== 'denied') {
              return;
            }
            this.riskApplications.denied = await _getData([
              this.helper.master.labels.riskApplication.status.DENIED,
            ], this.pagination.denied);
          })(),
        ]);
        this.flag.loader = false;
      } catch (error) {
        console.error(error);
        alert('画面表示情報の取得に失敗しました');
        throw error;
      }
    },
    getSearchParams(statuses, { page, perPage }) {
      return {
        statuses,
        page,
        perPage,
        with_reviewer_user: 0,
        with_counseling_data: 1,
        keyword: this.searchCondition.keyword || null,
        target_user_account_types: this.searchCondition.targetUserAccountTypes.length ? this.searchCondition.targetUserAccountTypes : null,
        applied_at_from: this.searchCondition.appliedAtFrom || null,
        applied_at_to: this.searchCondition.appliedAtTo || null,
        approval_action_at_from: this.searchCondition.approvalActionAtFrom || null,
        approval_action_at_to: this.searchCondition.approvalActionAtTo || null,
        risk_type: this.searchCondition.riskType || null,
        risk_type_tags: this.searchCondition.riskTypeTags.length ? this.searchCondition.riskTypeTags : null,
        exclude_test: this.searchCondition.flag.excludeTest ? true : null,
        school_id: this.searchCondition.targetSchoolId || null,
        applyer_user_id: this.searchCondition.targetMedicalWorkerId || null,
      };
    },
    /** 医師心理士の情報を取得 */
    getMedicalWorkers() {
      // 取得済みの場合は再取得しない
      if (this.medicalWorkersAll && this.medicalWorkersAll.length > 0) {
        return;
      }
      const params = {
        accountTypes: [
          21, // 医師
          22, // 心理士
        ],
        order: 'kana',
        isAddress: 0,
        isSchool: 0,
        isReadflags: 0,
      };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.medicalWorkersAll = res.users.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    /** 学校情報を取得 */
    getSchools() {
      // 取得済みの場合は再取得しない
      if (this.schoolsAll && this.schoolsAll.length > 0) {
        return;
      }
      this.axios({
        method: 'GET',
        url: '/v1/school/get/list',
        params: {
          isUsers: 0,
          isAddress: 0,
        },
      })
        .then((response) => {
          const res = response.data.data;
          this.schoolsAll = res.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    clickReview(riskApplication) {
      const childWindowName = this.getChildWindowName(riskApplication, true);
      // 別タブで開く
      window.open(`/operator/risk-applications/${riskApplication.id}/review`, childWindowName);
    },
    clickRefer(riskApplication) {
      const childWindowName = this.getChildWindowName(riskApplication, false);
      // 別タブで開く
      window.open(`/operator/risk-applications/${riskApplication.id}`, childWindowName);
      // 詳細表示済みidとして格納する
      this.referedIds.push(riskApplication.id);
    },
    /** 受付期間の始点と終点取得 */
    setSearchConditionAppliedAt(args) {
      const propKeys = args.name.split('_');
      if (args.date === 'Invalid date') this[propKeys[0]][propKeys[1]] = null;
      else this[propKeys[0]][propKeys[1]] = args.date;
      if (this.searchCondition.appliedAtFrom && !this.searchCondition.appliedAtTo) this.searchCondition.appliedAtTo = moment(new Date()).format('YYYY-MM-DD');
    },
    /** 報告期間の始点と終点取得 */
    setSearchConditionApprovalActionAt(args) {
      const propKeys = args.name.split('_');
      if (args.date === 'Invalid date') this[propKeys[0]][propKeys[1]] = null;
      else this[propKeys[0]][propKeys[1]] = args.date;
      if (this.searchCondition.approvalActionAtFrom && !this.searchCondition.approvalActionAtTo) this.searchCondition.approvalActionAtTo = moment(new Date()).format('YYYY-MM-DD');
    },
    /** 種別の選択 */
    changeRiskType() {
      // リスク種別の変更時はリスク報告理由の選択状態をクリアする
      this.searchCondition.riskTypeTags = [];
    },
    /** 学校の選択 */
    changeSchool(e) {
      this.searchCondition.targetSchoolId = Number(e.target.value);
      // 有意な学校を指定した時、テストカウント除外チェックは意味をなさないのでチェックを外す
      if (this.searchCondition.targetSchoolId) {
        this.searchCondition.flag.excludeTest = false;
      }
    },
    /** 検索 */
    clickSearch() {
      this.flag.search.isSearching = true;
      this.initializePagination();
      this.getRiskApplications();
    },
    /** 検索解除 */
    clickClear() {
      // 検索条件全クリア
      this.searchCondition = cloneDeep(SEARCH_CONDITION_DEFAULT);
      // 現在が絞り込み状態の場合は、検索を解除する
      if (this.flag.search.isSearching) {
        // 全データ再表示
        this.flag.search.isSearching = false;
        this.initializePagination();
        // 検索実行
        this.getRiskApplications();
      }
    },
    /**
     * このメソッドは子windowでロードされたRiskApplicationFormコンポーネントから実行される。
     * RiskApplicationForm上でリスク報告申請書へのアクションが行われた時、このメソッドを呼び出すことで情報の最新化を行う。
     */
    postUpdateRequest() {
      this.getRiskApplications(); // 再初期化する
    },
    /**
     * 子window名を決定返却するとともに、コールバック関数をセットする
     * @param riskApplication リスク報告書情報
     * @param needSetup セットする必要があるか
     */
    getChildWindowName(riskApplication, needSetup) {
      const childWindowName = raf.getChildWindowName({
        counselingType: riskApplication.counseling_type,
        foreignId: riskApplication.foreign_id,
      });
      if (needSetup) {
        // コールバック関数をセット
        const functionName = raf.getEmitFunctionName(childWindowName);
        if (!window[functionName]) {
          window[functionName] = this.postUpdateRequest;
        }
        // 子画面名を控えておく
        if (!this.childWindowNames.includes()) {
          this.childWindowNames.push(childWindowName);
        }
      }
      return childWindowName;
    },
    /** ページ移動 */
    movePage(page, type) {
      if (this.pagination[type].page === page) {
        return;
      }
      // 現在ページ変更
      this.pagination[type].page = page;
      // データ取得
      this.getRiskApplications(type);
      // スクロール
      document.getElementById(`risk-management-content-${type}`).scrollIntoView({
        behavior: 'smooth',
      });
    },
    initializePagination() {
      this.pagination = {
        applied: cloneDeep(PAGINATION_DEFAULT),
        review: cloneDeep(PAGINATION_DEFAULT),
        approved: cloneDeep(PAGINATION_DEFAULT),
        denied: cloneDeep(PAGINATION_DEFAULT),
      };
    },
  },
};
</script>

<style lang="scss" module>
.search {
  &_title {
    font-size: 1.1em;
    font-weight: bold;
  }
}
.input {
  outline: none;
  border: none;
  background-color: var(--gray-sub);
  width: 100%;
  padding: 15px;
  border-radius: 45px;
}
.searchArea {
  flex: 1;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 9em;
    i {
      margin-right: .5em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 2em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
        &:before {
          content: "~";
          margin-right: 1em;
        }
      }
    }
  }
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}
.alert_text {
  font-size: .8em;
  color: red;
}
.select {
  display: flex;
  > dt {
    width: 9em;
  }
  > dd {
    margin-inline-start: 0;
    align-items: center;
  }
  &_box {
    width: 300px;
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;
    margin-left: 2em;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.checkBox, .radio, .riskTypeTags, .targetUserAccountTypes {
  margin-left: 2em;
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}
.button_area {
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio {
  padding-top: 4px;
  display: flex;
  li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.riskTypeTags, .targetUserAccountTypes {
  display: flex;
  flex-wrap: wrap;
  row-gap: .5em;
  li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
dt {
  min-width: 9em;
}
.bold {
  font-weight: bold;
  font-size: 1.5em;
}
</style>
