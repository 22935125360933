<template>
  <div>
    <spacer :y="6"/>
    <ul>
      <stack-item>
        <contents-box title="ステータス" icon="<i class='fa-solid fa-user-doctor'></i>">
          <loader-simple :flag="loaderFlag">
            <div v-if="!loaderFlag && user.school && user.school.length && user.counseling">
              <stack-item>
                <template v-if="user.school[0].billing_type.billing_type === 1">
                  <p>{{ `オンライン・バーチャル健康相談　利用可能回数 ${user.school[0].count} 回` }}</p>
                  <spacer :y="2"/>
                </template>
                <!-- バーチャル健康相談 -->
                <contents-box
                  type="inner"
                  title="バーチャル健康相談"
                  icon="<i class='fa-solid fa-vr-cardboard'></i>"
                  iconColor="green"
                >
                  <loader-simple :flag="flag.isVirtual">
                    <div v-if="!flag.isVirtual">
                      <spacer :y="2"/>
                      <div>
                        <ul :class="$style.reserves">
                          <li v-for="row in virtualReserves" :key="row.id">
                            <div v-if="row.flag === 1" :class="$style.reserve">
                              <contents-box type="inner">
                                <p :class="$style.reserve_text" v-html="`【${row.fromUser.username}】 ${filterMoment(row.schedule.date, 'M月D日H時')}～バーチャル健康相談の予約が入っています。`"></p>
                                <spacer :y="2"/>
                                <div :class="$style.btn_wrap">
                                  <router-link :to="`/teacher/reserve/virtual/${row.id}`" :class="$style.btn">先生に伝える</router-link>
                                </div>
                              </contents-box>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div v-if="!virtualReserves.length">
                        <p>現在、バーチャル健康相談の予約はありません。</p>
                      </div>
                    </div>
                  </loader-simple>
                </contents-box>
                <spacer :y="2"/>
                <!-- オンライン健康相談 -->
                <contents-box
                  type="inner"
                  title="オンライン健康相談"
                  icon="<i class='fa-regular fa-laptop'></i>"
                  inner-icon="<i class='fa-regular fa-user-headset'></i>"
                  iconColor="orange"
                >
                  <loader-simple :flag="flag.isOnline">
                    <div v-if="!flag.isOnline">
                      <spacer :y="2"/>
                      <div>
                        <ul :class="$style.reserves">
                          <li v-for="row in reserves" :key="row.id">
                            <div v-if="row.flag === 1" :class="$style.reserve">
                              <contents-box type="inner">
                                <p :class="$style.reserve_text" v-html="`【${row.fromUser.username}】 ${filterMoment(row.schedule.date, 'M月D日H時')}～オンライン健康相談の予約が入っています。`"></p>
                                <spacer :y="2"/>
                                <div :class="$style.btn_wrap">
                                  <router-link :to="`/teacher/reserve/online/${row.id}`" :class="$style.btn">先生に伝える</router-link>
                                </div>
                              </contents-box>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div v-if="!reserves.length">
                        <p>現在、オンライン健康相談の予約はありません。</p>
                      </div>
                    </div>
                  </loader-simple>
                </contents-box>
              </stack-item>
              <stack-item>
                <contents-box
                  title="テキスト健康相談"
                  type="inner"
                  icon="<i class='fa-regular fa-comment-lines'>"
                  iconColor="blue"
                >
                  <loader-simple :flag="flag.isText">
                    <div v-if="!flag.isText">
                      <div v-if="texts.length">
                        <div :class="$style.reserve">
                          <p>相談中のテキスト健康相談が{{ texts.length }}件あります。</p>
                        </div>
                      </div>
                      <div v-else>
                        <p>相談中のテキスト健康相談はありません。</p>
                      </div>
                    </div>
                  </loader-simple>
                </contents-box>
              </stack-item>
              <stack-item v-if="operatorMessages.length">
                <contents-box type="inner" title="事務局メッセージ" icon="<i class='fa-regular fa-circle-info'></i>">
                  <spacer :y="2"/>
                  <ul :class="$style.operatorMessageList">
                    <li v-for="operatorMessage in operatorMessages" :key="operatorMessage.id">
                      <router-link :to="`/teacher/text/${operatorMessage.pivot.group_id}/`" :class="$style.link"
                        @click="registerReadFlag(operatorMessage.id)">
                        <div :class="$style.operator_wrap">
                          <div :class="$style.title_wrap">
                            <label-box
                              v-if="user.readflags && !user.readflags.operatorMessages.includes(operatorMessage.id)"
                              :class="$style.label"
                              label="NEW"
                              size="sm"
                              color="orange"/>
                            <spacer
                              :x="1"
                              v-if="user.readflags && !user.readflags.operatorMessages.includes(operatorMessage.id)"/>
                            <p :class="$style.operator_title">{{ operatorMessage.message }}</p>
                          </div>
                        </div>
                      </router-link>
                    </li>
                  </ul>
                </contents-box>
              </stack-item>
            </div>
          </loader-simple>
        </contents-box>
      </stack-item>

      <stack-item v-if="informations.length">
        <contents-box title="事務局からのお知らせ" icon="<i class='fa-regular fa-circle-info'></i>">
          <ul
            :class="$style.newsOperator">
            <li
              v-for="row in informations"
              :key="row.title">
              <router-link :to="`/teacher/information/${row.id}/`">
                <label-box
                  v-if="user.readflags && !user.readflags.informations.includes(row.id)"
                  :class="$style.label"
                  label="NEW"
                  size="sm"
                  color="orange"/>
                <spacer
                  :y="1"
                  v-if="user.readflags && !user.readflags.informations.includes(row.id)"/>
                <div :class="$style.label_wrap">
                  <label-box
                    :class="[$style.label, $style.date]"
                    :label="filterMoment(row.updated_at, 'YYYY-MM-DD')"
                    size="sm" color="orange"/>
                  <spacer
                    v-if="row.flag === 11"
                    :x="1"/>
                  <label-box
                    v-if="row.flag === 11"
                    :class="$style.label"
                    label="重要"
                    size="sm" color="pink"/>
                </div>
                <spacer :y="1.5"/>
                <p :class="$style.title">{{ row.title }}</p>
                <spacer :y="0.5"/>
                <div :class="$style.content"><p v-html="replaceImgTag(row.content)"></p></div>
              </router-link>
            </li>
          </ul>
          <spacer :y="3"/>
          <btn-container>
            <basic-btn to="/teacher/information/">一覧</basic-btn>
          </btn-container>
        </contents-box>
      </stack-item>

      <stack-item>
        <contents-box
          :title="helper.master
            && user.school
              ? helper.master?.labels?.organizationNews[user.school[0].is_company]
              : ''"
          icon="<i class='fa-regular fa-bullhorn'></i>">
          <ul>
            <stack-item>
              <contents-box type="inner">
                <div :class="$style.register">
                  <basic-btn
                    tag="button"
                    v-if="editId !== 0"
                    :addClass="'isCenter'"
                    v-on:click="editStart( { id: 0 })">新規登録</basic-btn>
                  <basic-btn
                    tag="button"
                    type="bdr"
                    size="sm"
                    v-if="editId === 0"
                    v-on:click="editStart({ id: null })">閉じる</basic-btn>
                </div>

                <div v-if="editId === 0">
                  <spacer :y="2"/>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    placeholder="タイトルを入力してください。"
                    v-model="title"
                    :disabled="flag.isConfirm"
                    :class="$style.textinput">

                  <spacer :y="2"/>
                  <Editor
                    name="textarea"
                    id="textarea"
                    rows="10"
                    v-model="content"
                    placeholder="投稿内容を入力してください。"
                    :class="$style.textarea"
                    v-bind:api-key="TINYMCE_API_KEY"
                    v-bind:init="editorConf"
                  />
                  <spacer :y="2"/>
                  <btn-container>
                    <basic-btn
                      tag="button"
                      v-on:click="regist($event, 1)">投稿
                    </basic-btn>
                    <spacer :x="2"/>
                    <basic-btn
                      tag="button"
                      type="bdr"
                      v-on:click="regist($event, 2)">下書き保存</basic-btn>
                    <spacer :x="2"/>
                  </btn-container>
                </div>
              </contents-box>
              <spacer :y="3"/>
            </stack-item>
          </ul>
          <ul>
            <stack-item>
              <contents-box type="inner">
                <ul :class="$style.newslist">
                  <li
                    v-for="row in schoolInformations"
                    :key="row.id">
                    <div :class="$style.school_info_wrap">
                      <div :class="$style.info_labels">
                        <label-box :class="[$style.label, $style.date]" :label="filterMoment(row.updated_at,'YYYY-MM-DD')" size="sm" color="gray"/>
                        <spacer :x="2"/>
                        <div :class="$style.title">
                          <span>{{ row.title }}</span>
                          <span
                            v-if="helper.master"
                            :class="$style.assistant">（{{ helper.master.labels.informations.flag[row.flag] }}）</span>
                        </div>
                      </div>
                        <i
                          :class="$style.pencil"
                          class="fa-solid fa-pen-to-square"
                          v-if="editId !== row.id"
                          v-on:click="editStart(row)"/>
                        <i
                          :class="$style.pencil"
                          class="fa-regular fa-circle-xmark"
                          v-if="editId === row.id"
                          v-on:click="editStart({ id: null })"/>
                    </div>
                    <div v-if="editId === row.id">
                      <spacer :y="2"/>
                      <div :class="$style.publishdate">
                        <p>公開日設定</p>
                        <spacer :y="1"/>
                        <date-small-picker
                          :class="$style.calendar"
                          :myDate="updated_at"
                          name="updated_at"
                          v-on:sendDate="receiveDate" />
                      </div>

                      <spacer :y="2"/>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="タイトルを入力してください。"
                        v-model="title"
                        :disabled="flag.isConfirm"
                        :class="$style.textinput">

                      <spacer :y="2"/>
                      <Editor
                        name="textarea"
                        id="textarea"
                        rows="10"
                        v-model="content"
                        placeholder="投稿内容を入力してください。"
                        :class="$style.textarea"
                        v-bind:api-key="TINYMCE_API_KEY"
                        v-bind:init="editorConf"
                      />

                      <spacer :y="3"/>
                      <btn-container
                        v-if="!flag.isConfirm">
                        <basic-btn
                          tag="button"
                          v-on:click="regist($event, 1)">投稿する</basic-btn>
                        <spacer :x="2"/>
                        <basic-btn
                          tag="button"
                          type="bdr"
                          v-on:click="regist($event, 2)">下書き保存</basic-btn>
                        <spacer :x="2"/>
                        <basic-btn
                          tag="button"
                          type="bg"
                          :addClass="'danger'"
                          v-on:click="regist($event, 999)">削除</basic-btn>
                      </btn-container>
                    </div>
                  </li>
                </ul>
                <spacer :y="3"/>
                <btn-container>
                  <basic-btn to="/teacher/schoolInformation/">一覧</basic-btn>
                </btn-container>
              </contents-box>
            </stack-item>
          </ul>
        </contents-box>
      </stack-item>
      <stack-item>
        <contents-box type="inner">
          <div :class="$style.regist">
            <div :class="$style.parallel">
              <i class="fa-solid fa-megaphone" :class="$style.icon"></i>
              <p :class="$style.regist_title">LINE登録</p>
            </div>
          </div>
          <p :class="$style.content_detail">オンライン健康相談の報告書 投函時、事務局個別メッセージ受信時にLINE通知が届きます。</p>
          <div :class="$style.flex_center">
            <img :class="$style.qr" :src="`/img/default/line_${helper.env.name}.png`">
            <p>LINE 公式アカウント</p>
          </div>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';
import cf from '@/mixins/commonFunctions.js';

const TINYMCE_API_KEY = process.env.VUE_APP_TINYMCE_API_KEY;

export default {
  name: 'myschool',
  mixins: [cf],
  components: {
    Editor,
    StackItem,
    ContentsBox,
    Spacer,
    LabelBox,
    BtnContainer,
    BasicBtn,
    LoaderSimple,
    DateSmallPicker,
  },
  data() {
    return {
      TINYMCE_API_KEY,
      flag: {
        isConfirm: false,
        isOnline: true,
        isVirtual: true,
        isText: true,
      },
      editId: null,
      informations: [],
      schoolInformations: [],
      reserves: [],
      virtualReserves: [],
      texts: [],
      title: null,
      content: null,
      updated_at: null,
      editorConf: {
        selector: 'textarea',
        content_css: '/editor/css/editor.css',
        language: 'ja',
        language_url: '/editor/langs/ja.js',
        menubar: false,
        plugins: 'lists link image code textcolor autolink',
        toolbar: 'styleselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link | undo redo | code',
        height: 400,
      },
      operatorMessages: [],
    };
  },
  created() {
    this.getSchoolsUsers();
    if (this.user.email) {
      if (this.user.account_type > 10 && this.user.account_type < 20) {
        this.getSchoolInformations();
        this.getInformations();
        this.getOperatorMessage();
        this.checkTabState();
      }
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          if (this.user.account_type > 10 && this.user.account_type < 20) {
            this.getSchoolInformations();
            this.getInformations();
            this.getOperatorMessage();
            this.checkTabState();
          }
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    loaderFlag() {
      let flag = true;
      if (this.user.counseling && this.user.counseling.reserves && this.user.counseling.virtualReserves) {
        flag = false;
      }
      return flag;
    },
  },
  methods: {

    /**
     * 学校に所属する利用者一覧のIDを取得する他タブも使用すると思うので
     * ログイン直後にstateに保存しても良いかも
    */
    getSchoolsUsers() {
      this.axios({
        method: 'GET',
        url: '/v2/school/get/userIdLite',
        params: { id: this.user.school[0].id },
      })
        .then(async (response) => {
          const res = response.data.data;
          this.userIds = await res.data[0].users.map((data) => data.id);
          // 学校に紐づく軽量版オンライン予約（未実施）取得
          this.countReservesLite();
          // 学校に紐づく軽量版バーチャル予約（未実施）取得
          this.countVirtualReservesLite();
          // 学校に紐づく軽量版テキスト予約取得
          this.countTextLite();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** 学校に所属する利用者の未実施のオンライン健康相談取得 */
    countReservesLite() {
      const params = {
        flags: [1],
        fromUser: this.userIds,
        counseling_type: 1, // オンライン
      };

      this.axios({
        method: 'GET',
        url: '/v2/reserve/get/liteList',
        params,
      })
        .then((response) => {
          const res = response.data.result.reserve;
          this.reserves = cf.upSortReserves(res);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isOnline = false;
        });
    },

    /** 学校に所属する利用者の未実施のオンライン健康相談取得 */
    countVirtualReservesLite() {
      const params = {
        flags: [1],
        fromUser: this.userIds,
        counseling_type: 3, // バーチャル健康相談
      };

      this.axios({
        method: 'GET',
        url: '/v2/reserve/get/liteList',
        params,
      })
        .then((response) => {
          const res = response.data.result.reserve;
          this.virtualReserves = cf.upSortReserves(res);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isVirtual = false;
        });
    },


    countTextLite() {
      const params = {
        target_type: 1,
        users: this.userIds,
        flags: [1, 21],
      };

      this.axios({
        method: 'GET',
        url: '/v2/messagegroup/get/liteList',
        params,
      })
        .then((response) => {
          const res = response.data.groups.data;
          this.texts = res;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isText = false;
        });
    },

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    // reserves() {
    //   const array = [];
    //   let count = 0;
    //   if (this.user.counseling && this.user.counseling.reserves && !this.user.counseling.reserves.future) {
    //     const rawArray = cf.upSortReserves(this.user.counseling.reserves);
    //     rawArray.forEach((row) => {
    //       if (row.flag === 1 && count < 5) {
    //         array.push(row);
    //         count += 1;
    //       }
    //     });
    //   }
    //   return array;
    // },
    // texts() {
    //   const array = [];
    //   if (this.user.counseling && this.user.counseling.texts && !this.user.counseling.texts.answered) {
    //     this.user.counseling.texts.forEach((row) => {
    //       if (row.messages[1]) {
    //         array.push(row);
    //       }
    //     });
    //   }
    //   return array.length;
    // },
    editStart(item) {
      this.isConfirm = false;
      this.editId = item.id;
      if (!item.id) {
        this.title = null;
        this.content = null;
        this.updated_at = null;
      } else {
        this.title = item.title;
        this.content = item.content;
        this.updated_at = item.updated_at;
      }
    },

    changeFlag() {
      this.flag.isConfirm = !this.flag.isConfirm;
    },

    /** 事務局個別メッセージ取得 */
    getOperatorMessage() {
      this.axios({
        method: 'GET',
        url: '/v1/messagegroup/get/list',
        params: {
          target_user_id: this.user.id,
          target_type: 2,
          isOperator: 1,
        },
      })
        .then((response) => {
          this.operatorMessages = response.data.groups.data.length
            ? response.data.groups.data.filter((group) => group.messages.length) // 一番初めのメッセージは事務局から
              .map((group) => group.messages.find((message) => message.user_id !== this.user.id))
            : [];
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** 事務局お知らせ取得 */
    getInformations() {
      this.axios({
        method: 'GET',
        url: '/v1/information/get/list',
        params: {
          targetNumber: 3,
          limit: 2,
          onwards: this.user.created_at,
        },
      })
        .then((response) => {
          this.informations = response.data.data.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** 学校だより・企業レター取得 */
    getSchoolInformations() {
      this.axios({
        method: 'GET',
        url: '/v1/schoolinformation/get/list',
        params: {
          school_id: this.user.school[0].id,
          includedDraft: 1,
          limit: 3,
          order: 'updated_at',
          orderBy: 'desc',
        },
      })
        .then((response) => {
          this.schoolInformations = response.data.schoolinformations.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** 学校だより・企業レター登録 */
    regist(e, flag) {
      e.preventDefault();
      if (!this.content) {
        return alert('投稿内容を入力してください。');
      }

      this.showLoading();

      const data = {
        id: this.editId === 0 ? null : this.editId,
        flag,
        user_id: this.user.id,
        school_id: this.user.school[0].id,
        title: this.title,
        content: this.content,
        updated_at: this.updated_at,
      };

      const endpoint = this.editId ? 'updater' : 'register';
      const label = this.editId ? '更新' : '登録';
      const type = this.helper.master && this.user.school ? this.helper.master.labels.organizationNews[this.user.school[0].is_company] : '学校だより・企業レター';

      this.axios({
        method: 'POST',
        url: `/v1/schoolinformation/set/${endpoint}`,
        data,
      })
        .then(() => {
          // 初期化
          this.flag.isConfirm = false;
          this.content = null;
          this.editId = null;

          this.schoolInformations = [];
          this.getSchoolInformations();
          if (flag === 999) {
            alert(`${type}を削除しました。`);
          } else if (flag !== 999) {
            alert(`${type}を${label}しました。`);
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    /** datepickerからの値受け取り */
    receiveDate(args) {
      if (!args.date) return;
      this[args.name] = args.date;
    },

    registerReadFlag(id) {
      if (this.user.readflags && !this.user.readflags.operatorMessages.includes(id)) {
        const data = {
          model_type: 6, // 事務局個別メッセージ
          user_id: this.user.id,
          foreign_id: id,
        };
        this.axios({
          method: 'POST',
          url: '/v1/readflag/set/register',
          data,
        })
          .then(async () => {
            await this.$store.dispatch('user/update');
          })
          .catch((error) => {
            if (error.message) console.log(error.message);
            else console.log(error);
          });
      }
    },

    /** LSにtab情報が保存されているか確認する */
    checkTabState() {
      const ls = cf.getLocalStorage('wtt');
      if (ls.saveState && ls.saveState.tab) {
        this.$router.replace({
          query: {
            tab: ls.saveState.tab,
          },
        });
        // localStorageのsaveStateを削除
        cf.deleteLocalStorage('wtt', 'saveState');
      }
    },
  },
};
</script>

<style lang="scss" module>
.register {
  display: flex;
  justify-content: flex-end;
  @include sm-view {
    display: block;
  }
}
.regist {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_title {
    font-size: 20px;
    font-weight: bold;
  }
  .parallel {
    display: flex;

    .icon {
      margin-right: 10px;
      font-size: 25px;
      text-align: center;
    }
  }
  @include sm-view {
    display: block;
  }
}
.pencil {
  font-size: 20px;
  color: var(--gray-side);
  cursor: pointer;
}
.school_info_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hideSp {
  @include sm-view {
    display: none;
  }
}
.info_labels {
  display: flex;
  align-items: flex-start;
  @include sm-view {
    display: block;
  }
}
.newsOperator {
  li {
    padding: 20px;
    border-bottom: 1px solid var(--gray-main);
    .title {
      font-weight: bold;
    }
  }
  a {
    color: var(--black);
    &:hover {
      .title {
        text-decoration: underline;
      }
    }
  }
}
.operatorMessageList {
  li:not(:last-child) {
    border-bottom: 1px solid var(--gray-main);
  }
  li {
    padding: 9px 0;
  }
}
.newslist {
  li {
    align-items: flex-start;
    padding: 20px;
    border-bottom: 1px solid var(--gray-main);
  }
}
.content {
  flex: 1;
  font-size: 12px;
  margin-top: 3px;
  overflow: hidden;
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  a {
    pointer-events: none;
  }
}
.textarea {
  width: 100%;
  // background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 20px;
}
.reserves {
  margin-top: -16px;
  a {
    color: var(--black);
    &:hover {
      text-decoration: underline;
    }
  }
  &_text {
    margin-top: 4px;
  }
}
.reserve {
  margin-top: 16px;
  a {
    color: #fff;
    &:hover {
      text-decoration: none;
      opacity: .7;
    }
  }
}
.btn {
  font-size: 12px;
  padding: 4px 16px;
  border-radius: 16px;
  color: #fff;
  background-color: var(--orange-main);
  @include sm-view {
    width: 100%;
    text-align: center;
  }
  &_wrap {
    display: flex;
    justify-content: center;
  }
}
.textinput {
  padding: 20px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  width: 100%;
  border-radius: 8px;
}
.assistant {
  font-size: 12px;
}
.publishdate {
  max-width: 200px;
}
.label {
  width: 80px;
  &_wrap {
    display: flex;
    @include sm-view {
      display: block;
      .label {
        width: 100%;
      }
    }
  }
  &.date {
    width: 120px;
    @include sm-view {
      width: 100%;
    }
  }
  @include sm-view {
    width: 100%;
  }
}
.link {
  color: var(--black);
  .operator_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      text-decoration: underline;
    }
  }
  &:hover {
    .title {
      text-decoration: underline;
    }
  }
  .infoDate {
    width: 120px;
    @include sm-view {
      width: 100%;
    }
  }
}
.operator_wrap {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  .title_wrap {
    display: flex;
    align-items: center;
    .operator_label {
      width: fit-content;
      min-width: 100px;
    }
  }
}

.content_detail {
  padding-top: 10px;
  padding-left: 20px;
}

.flex_center {
  margin-top: 30px;
  padding-bottom: 20px;
  text-align: center;
  .qr {
    margin: 0 auto;
    width: 120px;
  }
  p {
    padding-top: 20px;
  }
}
</style>
