<template>
  <loader-simple
    :flag="showLoading"
  >
    <p
      v-if="showLoading"
      :class="$style.loadingComment"
    >申請情報取得中です。しばらくお待ちください。</p>

    <not-found
      v-if="flag.notFound"
    />

    <risk-application-form
      v-if="flag.allowed"
      mode="apply"
      :clientId="clientId"
      :counselingType="counselingType"
      :foreignId="foreignId"
      :draftId="draftId"
      :base="draftedData"
      :isReduceRisk="isReduceRisk"
    />
  </loader-simple>
</template>

<script>
import { mapState } from 'vuex';
import RiskApplicationForm from '@/views/pages/Common/RiskApplicationForm.vue';
import NotFound from '@/views/404.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';

import raf from '@/mixins/riskApplicationFunctions';

/**
 * コンポーネントの責務
 * * 画面表示可否チェック
 * * 必要情報の取得と引き渡し
 *
 * クエリから次を解決する
 * * counseling_type
 *     // 1 => オンライン健康相談
 *     // 2 => テキスト健康相談
 *     // 3 => バーチャル健康相談
 * * foreign_id
 * * draft_id: 下書きから申請を再開する場合の risk_application_id
 */
export default {
  name: 'risk-application-apply',
  mixins: [
    raf,
  ],
  components: {
    RiskApplicationForm,
    NotFound,
    LoaderSimple,
  },
  data() {
    return {
      clientId: null,
      counselingType: null,
      foreignId: null,
      draftId: null,
      draftedData: null,
      flag: {
        notFound: false,
        allowed: false,
      },
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    showLoading() {
      return !this.flag.notFound && !this.flag.allowed;
    },
  },
  async beforeCreate() {
    if (this.user && this.user.account_type) {
      this.initialize();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.initialize();
        }
      });
    }
  },
  methods: {
    async initialize() {
      // パラメータチェック
      const query = this.$route.query;
      if ((!query.counseling_type || !query.foreign_id) && !query.draft_id) {
        this.setNotFound('Invalid parameters.');
      }
      // 権限チェック
      if (query.draft_id) {
        // 下書きからの申請時
        await this.validDraft(query.draft_id);
      } else {
        // 新規申請時
        await this.validApply(query.counseling_type, query.foreign_id);
      }

      // フォームを表示する
      this.flag.allowed = true;
    },

    async validDraft(draftId) {
      const response = await this.axios({
        method: 'GET',
        url: '/v1/riskApplications/get/detail',
        params: {
          risk_application_id: draftId,
        },
      });
      const riskApplication = response.data.data;
      // 下書きを扱えるかチェック
      if (!raf.isMyDraft({
        labels: this.helper.master.labels,
        user: this.user,
        riskApplication,
      })) {
        this.setNotFound('Not allowed to view this dfaft.');
      }
      // 権限チェックOK
      this.counselingType = riskApplication.counseling_type;
      this.foreignId = riskApplication.foreign_id;
      this.clientId = riskApplication.target_user_id;
      this.draftId = riskApplication.id;
      this.draftedData = riskApplication;
      this.isReduceRisk = riskApplication.is_risk_reduced;
      return true;
    },

    async validApply(counselingType, foreignId) {
      // アクターチェック
      const allowedAccountTypes = [
        21, // 医師
        22, // 心理士
      ];
      if (!allowedAccountTypes.includes(this.user.account_type)) {
        // 医師心理士以外には表示しない
        this.setNotFound('Not allowed to view this page.');
      }
      // 対象の健康相談がログイン中の医師心理士の担当かチェックする
      // そのために健康相談情報を取得する
      let clientId = null;
      const COUNSELINGTYPE_TEXT = '2'; // テキスト健康相談（クエリパラメータで渡ってくるので文字列型）
      if (counselingType === COUNSELINGTYPE_TEXT) {
        // テキスト健康相談
        const response = await this.axios({
          method: 'GET',
          url: '/v1/messagegroup/get/detail',
          params: {
            id: foreignId,
            includeDraft: 1, // 下書きを含む
          },
        });
        const detail = response.data.detail;
        if (!detail) {
          this.setNotFound('No text counseling data.');
        }
        clientId = detail.user_id;

      } else {
        // オンライン/バーチャル健康相談
        const response = await this.axios({
          method: 'GET',
          url: '/v1/reserve/get/detail',
          params: {
            id: foreignId,
            counseling_type: counselingType,
          },
        });
        const reserve = response.data.reserve;
        if (!reserve) {
          this.setNotFound('No reserve data.');
        }
        // ログインユーザが担当しているかチェック
        if (reserve.to_user_id !== this.user.id) {
          this.setNotFound('Not your reserve.');
        }
        clientId = reserve.from_user_id;
      }

      // 権限チェックOK
      this.counselingType = counselingType;
      this.foreignId = foreignId;
      this.clientId = clientId;
      this.isReduceRisk = !!this.$route.query.reduce_risk;
      return true;
    },

    setNotFound(msg) {
      console.log(msg);
      this.flag.notFound = true;
      throw new Error(msg); // 処理を中断するためErorrをthrowする
    },
  },
};
</script>

<style lang="scss" module>
.loadingComment {
  padding-top: 5rem;
  text-align: center;
}
</style>
